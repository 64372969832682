import React from "react";
import LocalizationProvider from "./LocalizationProvider";
import SanityProvider from "../sanity/SanityProvider";

/**
 * @todo refactor Sanity Provider into it's own functional component
 */
export default (props, options) => {
  return (
      <SanityProvider config={options.sanity}>
        <LocalizationProvider config={options.languages} url={props.props.location.pathname}>
          {props.element}
        </LocalizationProvider>
      </SanityProvider>
  )
}
import React from "react"
import SanityContext from "./SanityContext"
import {getFluidGatsbyImage} from 'gatsby-source-sanity'

export default ({config, children}) => {

  const value = {
    getFluidGatsbyImage: function (_id, maxWidth) {
      return getFluidGatsbyImage(_id, maxWidth, config)
    }
  }

  return (
      <SanityContext.Provider value={value}>{children}</SanityContext.Provider>
  )
}
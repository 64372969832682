import {ThemeProvider} from "emotion-theming";
import React from "react";

/**
 * GATSBY_THEME_PATH is defined in gatsby-node.js based on the program directory
 */

export default (props, options) => {
  // console.log('themeWrapRoot', props, options)
  return (
      <ThemeProvider theme={require(GATSBY_THEME_PATH).default}>{props.element}</ThemeProvider>
  )
}
const createLocaleTextGetter = (languageCode, defaultLanguageCode) => {
  // console.log("Filtering for language", languageCode)
  const languages = [languageCode, "en"] // last language in array is default;
  const localize = (value, languagesB, path = '') => {
    if(!value) return value;
    if (Array.isArray(value)) {
      return value.map(v => localize(v, languages))
    } else if (typeof value == "object") {
      if(value[defaultLanguageCode]) {
        // This is a translatable component
        if(value[languageCode]) {
          return value[languageCode];
        } else {
          // console.log(`Missing ${languageCode} for: ${path}`, value)
          return value[defaultLanguageCode];
        }
      }

      return Object.keys(value).reduce((result, key) => {
        result[key] = localize(value[key], languages, path + '.' + key) //.replace('_raw', '')
        return result
      }, {})
    }
    return value
  }
  return localize
}

module.exports = {
  createLocaleTextGetter: createLocaleTextGetter
}
import React from "react"
import {IntlProvider} from "react-intl"
import {createLocaleTextGetter} from '../utils'
import {getCurrentLangKey, getLangs, getUrlForLang} from 'ptz-i18n'
import LocalContext from "./LocaleContext";
import {Helmet} from 'react-helmet';


export default ({config, url, children}) => {

  const locale = getCurrentLangKey(config.langs, config.defaultLangKey, url)

  const homeLink = `/${locale}/`.replace(`/${config.defaultLangKey}/`, '/')
  const localize = createLocaleTextGetter(locale, config.defaultLangKey)

  const localeValue = {
    localizeData: (data) => localize(data),
    localizeUrl: (url) => {
      const langs =  getLangs(config.langs, locale, getUrlForLang(homeLink, url)).map((item) => ({
        ...item,
        link: item.link.replace(`/${config.defaultLangKey}/`, '/'),
      }))
      // console.log(url, langs)
      const test = langs.reduce((carry, lang) => (
          lang.langKey === locale ? lang : carry
      ))
      // console.log('AAA', url, test, langs);
      return test;
      // return getLangs(config.langs, locale, getUrlForLang(homeLink, url)).reduce((carry, item) => {
      //   console.log('url',item, locale, url, homeLink)
      //   return item.langKey === locale ? {
      //     ...item,
      //     link: item.link.replace(`/${config.defaultLangKey}/`, '/')
      //   } : carry
      // })
    },
    localizeDate: (dateTime) => {
      return new Date(Date.parse(dateTime)).toLocaleDateString(locale);
    },
    localizeDateTime: (dateTime) => {
      console.log('localizeDateTime', dateTime)
      return new Date(Date.parse(dateTime)).toLocaleString(locale);
    },
    locale: locale,
    // localizeString: (id) => intl.formatMessage({id: id}),
    languages: getLangs(config.langs, locale, getUrlForLang(homeLink, url)).map((item) => {
      /**
       * @todo implement linking to corresponding page not just home
       */
      return{
      ...item,
      link: `/${item.langKey}/`.replace(`/${config.defaultLangKey}/`, '/'), //item.link.replace(`/${config.defaultLangKey}/`, '/'),
      name: 'Jack',
    }})
  }

  return (
      <IntlProvider locale={locale} messages={config[locale]}>
        <LocalContext.Provider value={localeValue}>
          <Helmet>
            <html lang={locale} />
          </Helmet>
          {children.props.data ? React.cloneElement(children, {data: localize(children.props.data)}) : children}
        </LocalContext.Provider>
      </IntlProvider>
  )
}
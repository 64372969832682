import themeWrapRoot from './src/themeWrapRoot'
import wrapPage from "./src/locale/wrapPage";
//
import "./src/styles/global.scss"

export const wrapRootElement = themeWrapRoot
export const wrapPageElement = wrapPage

// export const onRenderBody = ({ setHeadComponents, setHtmlAttributes, setBodyAttributes }) => {
//   // console.log("Browser App", props);
//   console.log("RENDER BODY")
//   setBodyAttributes({className:"Jack"})
// }

